import request from '@/utils/request'
// 下订单
export const placeOrder = (data) => {
  return request('api/place_order', 'post', data)
}
// 取消订单
export const cancelOrder = (data) => {
  return request('api/cancel_order', 'post', data)
}
// 收货
export const orderReceipt = (data) => {
  return request('api/order_receipt', 'post', data)
}
// 支付宝签名
export const orderSign = (data) => {
  return request('api/order_sign', 'get', data)
}
// 申请售后
export const afterSales = (data) => {
  return request('api/after_sales', 'post', data)
}
// 申请售后
export const orderDetail = (data) => {
  return request('api/order_detail', 'get', data)
}
