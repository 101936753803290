import request from '@/utils/request'
/**
 * @description: 获取详情数据
 * @param {*} id
 * @return {*}
 */
export const findGoods = (goods_id) => {
  return request('api/goods_detail', 'get', { goods_id })
}
//游戏参数属性
export const ganeParams = (game_id, type, display) => {
  return request('api/gane_params', 'get', { game_id, type, display })
}
// 收藏
export const setCollect = (data) => {
  return request('api/set_collect', 'post', data)
}
//收藏列表
export const collectList = (data) => {
  return request('api/collect', 'get', data)
}
// 取消收藏
export const delCollect = (data) => {
  return request('api/del_collect', 'post', data)
}
// 上传
export const upload = (data) => {
  return request('api/get_sign', 'get', data)
}
// 发布商品
export const addGood = (data) => {
  return request('api/add_goods', 'post', data)
}
// 我的发布
export const myGoods = (data) => {
  return request('api/my_goods', 'get', data)
}
// 余额支付
export const balancePay = (data) => {
  return request('api/balance_pay', 'post', data)
}
//我的购买
export const myPurchase = (data) => {
  return request('api/my_purchase', 'get', data)
}
// 竞价出价
export const biddingOffer = (data) => {
  return request('api/bidding_offer', 'post', data)
}
// 顶一顶
export const goodsTop = (data) => {
  return request('api/goods_top', 'post', data)
}
// 下架商品
export const offShelfGoods = (data) => {
  return request('api/off_shelf_goods', 'post', data)
}
// 删除商品
export const delGoods = (data) => {
  return request('api/del_goods', 'post', data)
}
//协议
export const article = (data) => {
  return request('api/article', 'post', data)
}
