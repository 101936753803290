<template>
  <div class="sellform-page">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span v-if="route.query.type === 'sell'">平台帮卖</span>
      <span v-if="route.query.type === 'pushBidding'">发布竞价</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>填写商品信息</span>
    </div>
    <div class="box">
      <div class="title">
        <img src="@/assets/images/recreation-icon.png" alt="" />
        <p>选择区服</p>
      </div>
      <div class="content">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>当前游戏为</p>
        </div>
        <div class="operate" @click="gopage">
          <img :src="game.image" alt="" />
          <p>重新选择</p>
        </div>
      </div>
      <div class="params-select">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>账号信息</p>
        </div>
        <div class="account-info">
          <div class="account">
            <p>游戏账号</p>
            <input type="text" placeholder="请填写游戏账号" v-model="gameAccount" />
          </div>
          <div class="password">
            <p>游戏密码</p>
            <input type="password" placeholder="请填写游戏密码" v-model="gamePwd" />
          </div>
        </div>
      </div>
      <div class="params-select">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>上传商品方式</p>
        </div>
        <div class="way">
          <p :class="wayCurrent === 0 ? 'wayactive' : ''" @click="wayClick(0)">官方上传</p>
          <p :class="wayCurrent === 1 ? 'wayactive' : ''" @click="wayClick(1)">个人上传</p>
        </div>
      </div>
      <div class="params-select">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>填写商品信息</p>
        </div>
        <div class="goods-info">
          <div class="goods-title">
            <p>商品标题</p>
            <input type="text" placeholder="请填写商品标题" v-model="title" />
          </div>
          <div v-if="route.query.type === 'sell'" class="goods-title" style="margin-top: 20px">
            <p>商品价格</p>
            <input type="text" placeholder="请填写商品价格" v-model="price" />
          </div>
          <div
            v-if="route.query.type === 'pushBidding'"
            class="goods-title"
            style="margin-top: 20px"
          >
            <p>起拍竞价</p>
            <input type="text" placeholder="请填写起拍竞价" v-model="price" />
          </div>
          <div
            v-if="route.query.type === 'pushBidding'"
            class="goods-title"
            style="margin-top: 20px"
          >
            <p>加价幅度</p>
            <input type="text" placeholder="请填写加价幅度" v-model="incrPrice" />
          </div>
          <div
            v-if="route.query.type === 'pushBidding'"
            class="goods-title"
            style="margin-top: 20px"
          >
            <p>市场价</p>
            <input type="text" placeholder="请填写市场价" v-model="marketPrice" />
          </div>
          <div
            v-if="route.query.type === 'pushBidding'"
            class="goods-title"
            style="margin-top: 20px"
          >
            <p>保证金</p>
            <input type="text" placeholder="请填写保证金" v-model="deposit" />
          </div>
          <div class="goods-cover" v-if="wayCurrent === 1">
            <p>商品封面</p>
            <el-upload
              class="avatar-uploader avatar-uploader-bg"
              :action="host"
              :show-file-list="false"
              :auto-upload="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-change="handleChange"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <CirclePlus />
                <p>点击选择图片</p>
              </el-icon>
            </el-upload>
          </div>
          <div class="goods-cover" v-if="wayCurrent === 1">
            <p>游戏截图</p>
            <el-upload
              class="avatar-uploader"
              v-model:file-list="fileList"
              action="#"
              :auto-upload="false"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
              :on-change="handleListChange"
            >
              <el-icon>
                <CirclePlus />
                <p>点击选择图片</p>
              </el-icon>
            </el-upload>
            <!-- <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog> -->
          </div>
          <div class="goods-describe" v-if="wayCurrent === 1">
            <p>商品描述</p>
            <textarea
              name=""
              id=""
              cols="140"
              rows="10"
              placeholder="请输入商品描述"
              v-model="introduction"
            ></textarea>
          </div>
          <div class="compensate">
            <p>支持包赔</p>
            <div class="box">
              <p :class="compCurrent === 0 ? 'compensate-active' : ''" @click="compensateClick(0)">
                不支持
              </p>
              <p :class="compCurrent === 1 ? 'compensate-active' : ''" @click="compensateClick(1)">
                支持
              </p>
            </div>
          </div>
          <div style="margin-top: 20px">
            <div class="goods-filter-item" v-for="(item, index) in inputArr" :key="index">
              <p>{{ item.name }}</p>
              <input
                type="text"
                :placeholder="'请填写' + item.name"
                @input="screenInput(item, $event, index)"
                @blur="blurInput"
              />
            </div>
            <div class="goods-filter-item" v-for="(item, index) in radioArr" :key="index">
              <p>{{ item.name }}</p>
              <div
                class="goods-condition-right"
                :class="moreDevelop == false ? 'develop' : ''"
                :ref="goodsCondition.set"
              >
                <p
                  v-for="(option, i) in item.param"
                  :key="i"
                  :class="{ 'select-active': item.index === i }"
                  @click="selectClick(option, index, i)"
                >
                  {{ option }}
                </p>
              </div>
              <div class="goods-filter-right-more" @click="moreClick" v-if="moreBtn === true">
                <text>{{ moreText }}</text>
                <img :src="moreImg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="params-select">
        <div class="current">
          <img src="@/assets/images/vertical-icon.png" alt="" />
          <p>填写联系方式</p>
        </div>
        <div class="account-info">
          <div class="account">
            <p>联系电话</p>
            <input type="text" placeholder="请填写联系电话" v-model="mobile" />
          </div>
          <div class="password">
            <p>联系QQ</p>
            <input type="text" placeholder="请填写联系QQ" v-model="qq" />
          </div>
          <div class="password">
            <p>联系微信</p>
            <input type="text" placeholder="请填写联系微信" v-model="wechat" />
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="submit">提交</div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CirclePlus } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { upload, ganeParams, addGood } from '@/api/goods'
import { placeOrder } from '@/api/order'
import { useTemplateRefsList } from '@vueuse/core'
import axios from 'axios'
import path from 'path'
const route = useRoute()
const router = useRouter()
const store = useStore()
const game = ref({})
const wayCurrent = ref(0)
const compCurrent = ref(null)
const fileList = ref([])

const imageUrl = ref('')
const coverPhoto = ref('')
const host = ref('')
const radioArr = ref([])
const inputArr = ref([])
const goodsCondition = useTemplateRefsList()
const moreImg = ref(require('@/assets/images/icon-down.png'))
const moreText = ref('展开')
const moreDevelop = ref(true)
const moreBtn = ref(false)
let arr = []
const gameAccount = ref('')
const gamePwd = ref('')
const title = ref('')
const price = ref('')
const deposit = ref('')
const marketPrice = ref('')
const incrPrice = ref('')
const introduction = ref('')
const mobile = ref('')
const qq = ref('')
const wechat = ref('')
const isCompensate = ref(null)
const description = ref([])
function getGaneParams() {
  ganeParams(game.value.id).then((res) => {
    if (res.code == 200) {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].display === 0) {
          radioArr.value.push(res.data[i])
        } else if (res.data[i].display === 1) {
        } else if (res.data[i].display === 3) {
          inputArr.value.push(res.data[i])
        }
      }
      nextTick(() => {
        console.log(goodsCondition.value)
        for (let i = 0; i < goodsCondition.value.length; i++) {
          for (let g = 0; g < radioArr.value.length; g++) {
            radioArr.value[g].moreBtn =
              goodsCondition.value[i].clientHeight < goodsCondition.value[i].scrollHeight
          }
        }
      })
      console.log(radioArr.value)
    }
  })
}
function moreClick() {
  moreDevelop.value = !moreDevelop.value
  if (moreDevelop.value === false) {
    moreImg.value = require('@/assets/images/icon-upout.png')
    moreText.value = '收起'
  } else {
    moreImg.value = require('@/assets/images/icon-down.png')
    moreText.value = '展开'
  }
}
function selectClick(option, index, i) {
  radioArr.value[index].index = i
  let param = {
    id: radioArr.value[index].id,
    value: option
  }
  arr.push(param)
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i]['id'] == arr[j]['id']) {
        arr[i]['value'] = arr[j]['value']
        arr.splice(j, 1)
      }
    }
  }
}
function screenInput(item, e, index) {
  console.log(item)
  console.log(e)
  console.log(index)
  // if (e.data) {
  //   let obj = {
  //     id: item.id,
  //     name: multiple.value
  //   }
  //   arr.push(obj)
  // }
}
function blurInput(e) {
  //console.log(e.target.value)
}
function wayClick(e) {
  wayCurrent.value = e
}
function compensateClick(e) {
  compCurrent.value = e
  isCompensate.value = e
  console.log(isCompensate.value)
}
function handleAvatarSuccess(response, uploadFile) {
  console.log(response)
  console.log(uploadFile)
}
function beforeAvatarUpload(rawFile) {
  if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
    ElMessage.error('仅支持jpg、png格式!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
function handleChange(file) {
  upload().then((res) => {
    if (res.code == 200) {
      let code = ''
      const codeLength = 12
      const random = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ]
      for (let i = 0; i < codeLength; i++) {
        let index = Math.floor(Math.random() * 26)
        code += random[index]
      }
      host.value = res.data.host
      let formData = new FormData()
      formData.append('key', res.data.dir + '/' + code)
      formData.append('OSSAccessKeyId', res.data.accessid)
      formData.append('policy', res.data.policy)
      formData.append('Signature', res.data.signature)
      formData.append('file', file.raw)
      axios({
        method: 'post',
        url: res.data.host,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        imageUrl.value = response.request.responseURL + res.data.dir + '/' + code
        coverPhoto.value = res.data.dir + '/' + code
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}
function handleListChange(file) {
  upload().then((res) => {
    if (res.code == 200) {
      let code = ''
      const codeLength = 12
      const random = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ]
      for (let i = 0; i < codeLength; i++) {
        let index = Math.floor(Math.random() * 26)
        code += random[index]
      }
      host.value = res.data.host
      let formData = new FormData()
      formData.append('key', res.data.dir + '/' + code)
      formData.append('OSSAccessKeyId', res.data.accessid)
      formData.append('policy', res.data.policy)
      formData.append('Signature', res.data.signature)
      formData.append('file', file.raw)
      axios({
        method: 'post',
        url: res.data.host,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        for (let i = 0; i < fileList.value.length; i++) {
          if (fileList.value[i].uid === file.uid) {
            fileList.value[i].name = res.data.dir + '/' + code
          }
        }
        console.log(fileList.value)
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}
function handleRemove(uploadFile, uploadFiles) {
  console.log(uploadFile)
  console.log(uploadFiles)
}
function handlePictureCardPreview(uploadFile) {
  console.log(uploadFile)
}
function submit() {
  if (!gameAccount.value) {
    ElMessage.error('请填写游戏账号!')
    return
  }
  if (!gamePwd.value) {
    ElMessage.error('请填写游戏密码!')
    return
  }
  if (!title.value) {
    ElMessage.error('请填写商品标题!')
    return
  }
  if (route.query.type === 'sell') {
    if (!price.value) {
      ElMessage.error('请填写商品价格!')
      return
    }
  } else if (route.query.type === 'pushBidding') {
    if (!price.value) {
      ElMessage.error('请填写起拍竞价!')
      return
    }
    if (!incrPrice.value) {
      ElMessage.error('请填写加价幅度!')
      return
    }
    if (!deposit.value) {
      ElMessage.error('请填写保证金!')
      return
    }
    if (!marketPrice.value) {
      ElMessage.error('请填写市场价!')
      return
    }
  }
  if (isCompensate.value === null) {
    ElMessage.error('请选择是否支持包赔!')
    return
  }
  if (!mobile.value) {
    ElMessage.error('请填写电话!')
    return
  }
  if (!qq.value) {
    ElMessage.error('请填写qq!')
    return
  }
  if (!wechat.value) {
    ElMessage.error('请填写微信!')
    return
  }
  for (let i = 0; i < fileList.value.length; i++) {
    description.value.push(fileList.value[i].name)
  }
  let params = {
    game_id: game.value.id,
    game_account: gameAccount.value,
    game_pwd: gamePwd.value,
    title: title.value,
    type: 0,
    mobile: mobile.value,
    qq: qq.value,
    wechat: wechat.value,
    params: arr,
    is_compensate: isCompensate.value
  }
  if (imageUrl.value) {
    params.image = []
    params.image.push(coverPhoto.value)
  }
  if (description.value.length > 0) {
    params.description = description.value
  }
  if (introduction.value) {
    params.introduction = introduction.value
  }
  if (price.value) {
    params.price = Number(price.value) * 100
  }
  if (incrPrice.value) {
    params.incr_price = Number(incrPrice.value) * 100
  }
  if (deposit.value) {
    params.deposit = deposit.value * 100
  }
  if (marketPrice.value) {
    params.market_price = marketPrice.value * 100
  }
  if (route.query.type === 'sell') {
    params.type = 0
  } else if (route.query.type === 'pushBidding') {
    params.type = 2
  }
  addGood(params).then((res) => {
    if (res.code == 200) {
      if (route.query.type === 'sell') {
        ElMessage.success(res.msg)
        setTimeout(() => {
          router.push({ path: '/examine', query: { type: 'sell' } })
        }, 2000)
      } else if (route.query.type === 'pushBidding') {
        ElMessage.success(res.msg)
        const orderParams = {
          goods_id: res.data.goods_id,
          type: 2,
          amount: price.value,
          mobile: mobile.value,
          qq: qq.value,
          wechat: wechat.value,
          deposit: deposit.value
        }
        setTimeout(() => {
          router.push({
            path: '/pay',
            query: { type: 'pushBidding', params: JSON.stringify(orderParams) }
          })
        }, 2000)
      }
    } else {
      ElMessage.error(res.msg)
    }
  })
}
function gopage() {
  if (route.query.type === 'sell') {
    router.push({ path: '/allGame', query: { type: 'sell' } })
  } else if (route.query.type === 'pushBidding') {
    router.push({ path: '/allGame', query: { type: 'pushBidding' } })
  }
}
onMounted(() => {
  game.value = store.state.sell.game
  getGaneParams()
})
</script>
<style lang="less" scoped>
.ant-modal-content {
  border-radius: 20px;
  .ant-modal-body {
    padding: 0;
  }
}
.sellform-page {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.breadcrumb {
  margin: 14px 0;
  span:nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }
  img {
    margin: 0 3px;
  }
  span:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
}
.box {
  .title {
    width: 1200px;
    height: 45px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 12px 12px 0px 0px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    img {
      width: 20px;
      height: 15px;
    }
    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-left: 5px;
    }
  }
  .content {
    width: 1200px;
    height: 174px;
    background: #ffffff;
    text-align: center;
    border-radius: 0 0 12px 12px;
    padding-top: 25px;
    .current {
      display: flex;
      align-items: center;
      margin-left: 18px;
      img {
        height: 16px;
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 5px;
      }
    }
    .operate {
      display: flex;
      position: relative;
      margin-left: 28px;
      margin-top: 16px;
      img {
        width: 80px;
        height: 80px;
        position: relative;
      }
      p {
        width: 78px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: #d5f2ff;
        border-radius: 8px;
        border: 1px solid #40bdef;
        position: absolute;
        bottom: 0;
        left: 100px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #06a4f9;
        cursor: pointer;
      }
    }
  }
  .params-select {
    width: 1200px;
    background: #ffffff;
    border-radius: 12px;
    margin-top: 16px;
    padding: 30px;
    .current {
      display: flex;
      align-items: center;
      //margin-left: 18px;
      img {
        height: 16px;
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 5px;
      }
    }
    .account-info {
      input {
        width: 300px;
        height: 36px;
        background: #f6f7fb;
        border-radius: 8px;
        margin-left: 28px;
        padding-left: 10px;
      }
      p {
        width: 65px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .account {
        display: flex;
        margin-top: 16px;
        align-items: center;
      }
      .password {
        display: flex;
        margin-top: 24px;
        align-items: center;
      }
    }
    .way {
      display: flex;
      margin-top: 16px;
      p {
        width: 120px;
        height: 36px;
        background: #f6f7fb;
        border-radius: 8px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
        line-height: 36px;
        margin-left: 10px;
        cursor: pointer;
      }
      .wayactive {
        border: 1px solid #40bdef;
        background: #d5f2ff;
        color: #06a4f9;
      }
    }
    .goods-info {
      margin-left: 10px;
      margin-top: 16px;
      .goods-title {
        display: flex;
        align-items: center;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        input {
          width: 300px;
          height: 36px;
          background: #f6f7fb;
          border-radius: 8px;
          margin-left: 28px;
          padding-left: 10px;
        }
      }
      .goods-cover {
        display: flex;
        margin-top: 24px;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-right: 28px;
        }
        .avatar-uploader {
          //   width: 274px;
          height: 150px;
          border-radius: 8px;
        }
        .avatar-uploader-bg {
          background: #f6f7fb;
        }
        .avatar-uploader .avatar {
          width: 274px;
          height: 150px;
          display: block;
          background: #f6f7fb;
        }
        :deep(.el-upload--picture-card) {
          border: none !important;
          background: #f6f7fb;
          width: 274px;
          display: block;
          text-align: center;
        }
        .avatar-uploader .el-upload:hover {
          border-color: var(--el-color-primary);
        }
        .el-icon {
          display: inline-block;
          p {
            margin-right: 0;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c9c9c9;
          }
        }
        .el-icon {
          font-size: 32px;
          color: #c3c5c9;
          width: 274px;
          text-align: center;
          margin-top: 40px;
        }
      }
      .goods-describe {
        display: flex;
        margin-top: 24px;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-right: 28px;
        }
        textarea {
          background: #f6f7fb;
          padding-left: 10px;
          padding-top: 7px;
          border-radius: 8px;
          border: none;
        }
      }
      .compensate {
        display: flex;
        margin-top: 24px;
        p:nth-child(1) {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-right: 28px;
        }
        .box {
          display: flex;
          p {
            width: 120px;
            height: 36px;
            background: #f6f7fb;
            border-radius: 8px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            text-align: center;
            line-height: 36px;
            margin-left: 10px;
            cursor: pointer;
          }
          .compensate-active {
            border: 1px solid #40bdef;
            background: #d5f2ff;
            color: #06a4f9;
          }
        }
      }
      .goods-filter-item {
        display: flex;
        //height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        input {
          margin-left: 20px;
          width: 300px;
          height: 36px;
          background: #f6f7fb;
          border-radius: 8px;
          padding-left: 10px;
        }
        .goods-condition-game {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          white-space: nowrap;
        }
        .goods-condition-right {
          width: 900px;
          margin: 0 20px;
          height: 40px;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          p {
            padding: 0 10px;
            //background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .select-active {
            background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
            color: #06a4f9;
          }
          input {
            width: 122px;
            height: 32px;
            background: #f4f4f4;
            border-radius: 4px;
            padding-left: 16px;
          }
          text {
            margin: 0 8px;
          }
          .keywords-input {
            width: 290px;
          }
          .btn {
            width: 72px;
            height: 32px;
            float: right;
            text-align: center;
            margin-left: 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
            border-radius: 5px;
            cursor: pointer;
          }
          .input-box {
            float: right;
          }
        }
        .develop {
          height: auto;
        }
        .goods-filter-right-more {
          display: flex;
          width: 68px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          img {
            width: 8px;
            height: 5px;
            margin-left: 6px;
          }
        }
      }
    }
  }
}
.btn {
  width: 304px;
  height: 52px;
  background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
  border-radius: 8px;
  text-align: center;
  line-height: 52px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 36px;
  cursor: pointer;
}
</style>
